<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Date picker -->
          <b-col cols="12" md="6">
            <b-row>
              <b-col md="5 p-0 mr-1">
                <label for="example-input">From Date</label>
                <b-form-datepicker
                  id="from-date"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short',
                  }"
                  placeholder="Choose a date"
                  v-model="search_filter.from_date"
                />
              </b-col>
              <b-col md="5 p-0">
                <label for="example-input">To Date</label>
                <b-form-datepicker
                  id="to-date"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short',
                  }"
                  placeholder="Choose a date"
                  v-model="search_filter.to_date"
                />
              </b-col>
              <b-col md="1 d-flex align-items-end">
                <b-button @click="getFeedInventory()" variant="primary ">
                  Filter
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            class="mt-2 d-flex justify-content-end align-items-center"
          >
            <div>
              <b-form-input
                type="text"
                class="form-control"
                placeholder="Search"
                @input="getFeedInventory()"
                v-model.trim="search_filter.input_field"
                style="border-radius: 4px"
              />
            </div>
            <b-button
              @click="clearFilter"
              variant="secondary
 			 ml-1"
            >
              Clear
            </b-button>
            <b-button @click="excelDownload()" variant="warning" class="ml-1">
              <feather-icon icon="DownloadIcon" size="16" />
            </b-button>
          </b-col>
        </b-row>
      </div>

      <div v-if="feedInventory && feedInventory.total > 0">
        <b-table
          id="feed-stock"
          responsive
          :items="feedInventory.data"
          :fields="fields"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(index)="data">
            <div class="ml-1">
              {{ feedInventory.from + data.index }}
            </div>
          </template>
          <template #cell(production_type)="data">
            <span v-if="data.item.production_type">{{
              data.item.production_type.name
            }}</span>
            <span v-else>NA</span>
          </template>
          <template #cell(feed)="data">
            <span v-if="data.item.feed">{{ data.item.feed.name }}</span>
            <span v-else>NA</span>
          </template>
          <template #cell(total_feed)="data">
            <div class="ml-1">
              {{ data.item.purchase ? data.item.purchase.feed_qty : 0 }}
            </div>
          </template>

          <!-- Column: Id -->
          <template #cell(id)="data"> #{{ data.value }} </template>
        </b-table>
        <div class="d-flex justify-content-end mb-3 mt-2 mr-2">
          <b-button variant="primary">
            Total Feed Stock : {{ this.feed_stock ? feed_stock : 0 }}
          </b-button>
        </div>
        <div>
          <b-pagination
            :value="feedInventory.current_page"
            :total-rows="feedInventory.total"
            :per-page="feedInventory.per_page"
            align="right"
            @change="getFeedInventory"
          ></b-pagination>
        </div>
      </div>
      <div v-else class="d-flex flex-column align-items-center py-5">
        <b-img
          :src="
            require('@/assets/images/svg/infographics/no_data_infographic.svg')
          "
          class="mr-2"
        />
        <h4 class="mt-2 Secondary">Feed stock is Empty</h4>
      </div>
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import { BASE_URL } from "@core/common/constants";
import moment from "moment";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      selected: null,
      searchQuery: null,
      search_filter: {
        input_field: "",
        to_date: "",
        from_date: "",
      },
      feed_stock: null,
      fields: [
        { key: "index", label: "S.no" },
        { key: "feed", label: "Feed Name" },
        { key: "production_type", label: "production type" },
        "purchase_date",
        // "name",
        "source_id",
        "quantity",
        {
          key: "rate_per_kg",
          label: "Rate per Kg",
        },
        {
          key: "total_cost",
          label: "Total Cost",
        },
      ],
      feedInventory: {},
      total_feed_count: null,
    };
  },
  created: function () {
    this.getFeedInventory();
  },
  methods: {
    moment() {
      return moment();
    },
    getFeedInventory(pageNo = 1) {
      let farmId = this.$route.params.farmId;
      let url = `web/farm/${farmId}/feed-stock`;
      let queryParams = {
        page: pageNo,
      };

      if (this.search_filter.input_field) {
        queryParams.input_field = this.search_filter.input_field;
      }
      if (this.search_filter.to_date) {
        queryParams.to_date = this.search_filter.to_date;
      }
      if (this.search_filter.from_date) {
        queryParams.from_date = this.search_filter.from_date;
      }

      axiosIns
        .get(this.addQueryString(url, queryParams))
        .then((response) => {
          this.feedInventory = response.data.feedInventory;
          this.feed_stock = response.data.total_feed;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },

    excelDownload() {
      if (this.search_filter.from_date && this.search_filter.to_date) {
        this.search_filter.from_date = moment(
          this.search_filter.from_date
        ).format("YYYY-MM-DD");
        this.search_filter.to_date = moment(this.search_filter.to_date).format(
          "YYYY-MM-DD"
        );
      }
      let order_filter_data = this.prepareForm(this.search_filter);
      axiosIns
        .post(`web/export-feed-stock`, order_filter_data)
        .then((response) => {
          var tempLink = document.createElement("a");
          tempLink.style.display = "none";
          tempLink.href = response.data.file;
          tempLink.setAttribute("download", response.data.name);
          if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
          }
          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
          this.search_filter.to_date = null;
          this.search_filter.from_date = null;
          this.search_filter.input_field == null;
          this.getFeedInventory();
        })
        .catch((error) => {
          error.response;
          const data = error.response.data.errors;
          data, "data";
          let arr = Object.values(data).flat().toString(",");
          arr, "array";
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              text: arr,
            },
          });
          this.search_filter.to_date = null;
          this.search_filter.from_date = null;
          this.search_filter.input_field == null;
          this.getFeedInventory();
        });
    },

    clearFilter() {
      this.search_filter.input_field = "";
      this.search_filter.to_date = "";
      this.search_filter.from_date = "";
      this.getFeedInventory();
    },
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
